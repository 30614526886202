
const Client = require('./client');
class Reports extends Client{
    constructor() {
        super();
        this._endpoint = 'report';
    }
    generateUrl(endpoint,filter) {
        var url  = endpoint;
        var filters = [];
        for (const [key, value] of Object.entries(filter)) {
            filters.push(`${key}=${value}`);
        }
        url += "?" + filters.join('&');
        return url
    }
    report(filter){
        return new Promise(function(resolve,reject){
            this.get(this.generateUrl(this._endpoint,filter))
            .then((result)=>{
                if (result.success) {
                    resolve(result.data)
                } else {
                    reject(result)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}

module.exports = Reports;